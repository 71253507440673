import { isValidEmail, joinCommasAnd, normalizeString } from "@outschool/text";
import * as Time from "@outschool/time";
import { dayjs } from "@outschool/time";
import { DEFAULT_PARENT_NAME } from "@outschool/user";
import emojiStrip from "emoji-strip";
import _ from "lodash";

import * as Env from "./Env";
import * as Geocoder from "./Geocoder";

const MIN_LEARNER_AGE = 3;
const MAX_LEARNER_AGE = 18;

export {
  /**
   * @deprecated use import { DEFAULT_PARENT_NAME } from `@outschool/user`
   */
  DEFAULT_PARENT_NAME
};

export const NAME_MAX_LENGTH = 50;
export const MAX_OTHER_EMAILS_PER_USER = 3;

export function isDeleted(user) {
  return user && !!user.deleted_at;
}

export function stripEmojis(str: string, fallback = "Parent") {
  const newName = emojiStrip(str).trim();
  return Boolean(newName) ? newName : fallback;
}

export function publicName(user, noInitial?) {
  if (
    !user.name ||
    !user.name.trim() ||
    (user.details && user.details.socialOptOut) ||
    isDeleted(user)
  ) {
    return "";
  }

  if (isSellerOrg(user)) {
    return user.name;
  }
  return splitName(user.name, noInitial);
}

export function splitName(name, noInitial) {
  const words = name.trim().split(" ");
  if (words[1] && !noInitial) {
    return startCase(words[0]) + " " + startCase(words[1][0]) + ".";
  } else {
    return startCase(words[0]);
  }
}

export function shortenedLeaderName(leaderName) {
  if (!leaderName) {
    return "";
  }
  if (leaderName.length <= 30) {
    return leaderName;
  }
  return leaderName.slice(0, 30).concat("...");
}

function startCase(word) {
  if (word.length === 0) {
    return "";
  } else if (word.length === 1) {
    return word.toLocaleUpperCase();
  }
  return word[0].toLocaleUpperCase() + word.slice(1).toLocaleLowerCase();
}

export function parentName(user, noInitial?) {
  const name = publicName(user, noInitial);
  if (name === "" || isDeleted(user)) {
    return DEFAULT_PARENT_NAME;
  }
  return name;
}

export function senderName(user) {
  return (user && (user.name || user.email)) || "";
}

export function isEducator(user) {
  return !!(user && user.leader_subscribed_at);
}

export function isAdmin(user) {
  return !!(user && user.is_admin);
}

export function hasRole(user, role, inherit = true) {
  return Boolean(
    user &&
      ((inherit && isAdmin(user)) ||
        (Array.isArray(user.roles) && user.roles.includes(role)))
  );
}

function locationTimeZone(user) {
  return user && user.location && user.location.timeZone;
}

function browserTimeZone(user) {
  return Env.isBrowser
    ? Time.guessBrowserTimeZone()
    : user && user.details && user.details.browserTimeZone;
}

export function preferredTimeRanges(user) {
  const { details } = user || {};
  if (!details) {
    return [];
  }
  const { timeRangeSchool, timeRangeAfterSchool, timeRangeWeekend } = details;
  if (
    timeRangeSchool !== false &&
    timeRangeAfterSchool !== false &&
    timeRangeWeekend !== false
  ) {
    return [];
  }
  const times = ["School hours", "After school", "Weekend"];
  const timesNotWanted: string[] = [];
  if (timeRangeSchool === false) {
    timesNotWanted.push("School hours");
  }
  if (timeRangeAfterSchool === false) {
    timesNotWanted.push("After school");
  }
  if (timeRangeWeekend === false) {
    timesNotWanted.push("Weekend");
  }
  return _.difference(times, timesNotWanted);
}

export function geoResultToLocation(geoResult) {
  return {
    lat: geoResult.geometry.location.lat,
    lng: geoResult.geometry.location.lng,
    city: Geocoder.resultToCity(geoResult),
    state: Geocoder.resultToState(geoResult),
    geo: JSON.stringify(geoResult),
    address: geoResult.formatted_address
  };
}

export function isEmailConfirmed(user) {
  return !!user.confirmed_at;
}

export function stripUserMetadata(user) {
  // TODO: use a whitelist here instead
  const safeUser = _.omit(user, [
    "salt",
    "derived_key",
    "created_at",
    "updated_at",
    "chaperone_required"
  ]);
  if (!safeUser.background_checked_at) {
    // Hide the existence of this field unless it is set.
    delete safeUser.background_checked_at;
  }
  safeUser.details = _.omit(user.details, [
    "confirmEmailToken",
    "confirmEmailIssuedAt",
    "changePasswordToken",
    "changePasswordIssuedAt",
    "holdMessages"
  ]);
  return safeUser;
}

export function publicViewOfLeader(leader) {
  const safeLeader = _.pick(leader, [
    "uid",
    "name",
    "photo",
    "is_admin",
    "leader_link",
    "leader_subscribed_at"
  ]);
  return {
    ...safeLeader,
    details: _.pick(leader.details, [
      "about",
      "leaderTopics",
      "photo",
      "headline",
      "socialOptOut",
      "videoUrl"
    ]),
    location: {}
  };
}

export function publicViewOfParent(parent) {
  const safeParent = _.pick(parent, ["uid", "name", "photo"]);
  return {
    ...safeParent,
    details: _.pick(parent.details, ["photo", "introduction"]),
    publicName: parentName(parent)
  };
}

export function leaderLinkForUser(name) {
  return (name && _.words(name).join("-")) || null;
}

export function hasBackgroundCheckInProgress(user) {
  return Boolean(
    (!user.background_checked_at && !!user.background_check_started_at) ||
      (user.background_checked_at &&
        user.background_check_started_at &&
        dayjs(user.background_check_started_at).isAfter(
          user.background_checked_at
        ))
  );
}

type UserBackgroundCheck = {
  background_checked_at: dayjs.ConfigType | null;
};

export function hasBackgroundChecked(user: UserBackgroundCheck) {
  return user.background_checked_at !== null;
}

export function backgroundCheckExpiry(user: UserBackgroundCheck) {
  return !!user.background_checked_at
    ? dayjs(user.background_checked_at).add(1, "year")
    : null;
}

export function hasBackgroundCheckExpired(user: UserBackgroundCheck): boolean {
  const expiry = backgroundCheckExpiry(user);
  return !!expiry && expiry.isBefore();
}

export function hasUKBackgroundCheck(user) {
  return timeZone(user).includes("Europe/London");
}

export function isBackgroundCheckAboutToExpire(user: UserBackgroundCheck) {
  const expiry = backgroundCheckExpiry(user);
  return !!expiry && expiry.subtract(4, "week").subtract(2, "day").isBefore();
}

export function hasLocation(user) {
  return (
    user.location &&
    user.location.city &&
    user.location.lat !== undefined &&
    user.location.lng !== undefined
  );
}

export function cityState(user) {
  return user.location && user.location.city && user.location.state
    ? `${user.location.city}, ${user.location.state}`
    : (user.location && user.location.country) || null;
}

export function hasTimePreferences(user) {
  // User has time preferences if at least one is true or false.
  // By default they're all null or undefined
  // (but not necessarily absent from user.details)
  return Boolean(
    user.details &&
      (user.details.timeRangeSchool ||
        user.details.timeRangeAfterSchool ||
        user.details.timeRangeWeekend ||
        user.details.timeRangeSchool === false ||
        user.details.timeRangeWeekend === false ||
        user.details.timeRangeAfterSchool === false)
  );
}

export function timeZone(
  user,
  defaultTimeZone: string | null | undefined = Time.OUTSCHOOL_TIMEZONE
) {
  const locationZone = locationTimeZone(user);
  if (Time.zoneIsValid(locationZone)) {
    return locationZone;
  }
  const browserZone = browserTimeZone(user);
  if (Time.zoneIsValid(browserZone)) {
    return browserZone;
  }
  return defaultTimeZone;
}

export function firstAndLastName(user) {
  if (isDeleted(user) || !user.name) {
    return [];
  }

  const tokens = user.name.split(" ");
  const firstTokenEndsWithDot = /\.$/.test(tokens[0]); // e.g. "Mrs. Betty"

  // e.g. "Betty" or "Betty Smith
  if (tokens.length <= 2 && !firstTokenEndsWithDot) {
    return [tokens[0], tokens[1]];
  }

  // e.g. "Mrs. Betty Smith" or "Mrs. Betty Smith Jr."
  return [
    `${tokens[0]} ${tokens[1] || ``}`.trim(),
    tokens.length > 2 ? tokens.slice(2).join(" ") : undefined
  ];
}

/**
 * @deprecated use `User.firstname` from `@outschool/user`
 */
export function firstName(user) {
  if (user && user.name && user.name === DEFAULT_PARENT_NAME) {
    return user.name;
  }
  const names = firstAndLastName(user);
  return names && names[0];
}

export function accountText(user, isLeader) {
  if (user && user.name) {
    return isLeader ? user.name : firstName(user);
  }

  return "Account";
}

export function emailsMatch(email1, email2) {
  return normalizeString(email1) === normalizeString(email2);
}

export function otherEmailsMatch(userOtherEmails, email) {
  return Boolean(
    !!userOtherEmails && userOtherEmails.some(e => emailsMatch(e, email))
  );
}

export function ageString(user) {
  return user
    ? user.children
        .map(c => c.age)
        .sort()
        .join(", ")
    : "";
}

export function extractAges(
  learners: { age?: number | null }[] = []
): number[] {
  const ages: number[] = [];
  for (const learner of learners) {
    const age = learner?.age;
    if (!!age && MIN_LEARNER_AGE <= age && age <= MAX_LEARNER_AGE) {
      ages.push(age);
    }
  }
  return _.sortBy(_.uniq(ages));
}

export function preferredAges(user, learners?): number[] {
  if (Array.isArray(learners)) {
    return extractAges(learners);
  }
  if (!!user?.children) {
    return extractAges(user.children);
  }
  return [];
}

export function profileCompletenessSteps(user) {
  //TODO: Once server rendered site is tagged move this to shared localization page
  return {
    "Sign up": true,
    "Tell us your name": !!(user && user.name),
    "Choose a photo": !!(user && user.photo),
    "Tell us about your learners": user.children.length > 0,
    "Tell us when you can take classes": !!hasTimePreferences(user),
    "Set your location":
      !!(user && user.location) &&
      !_.isEmpty(user.location) &&
      _.some(user.location)
  };
}

export function profileCompletenessPercent(user) {
  const steps = profileCompletenessSteps(user);
  const stepValues = _.values(steps);

  return ~~((_.compact(stepValues).length * 100) / stepValues.length);
}

export function profileCompletenessItems(user) {
  const steps = profileCompletenessSteps(user);

  return _.reduce<typeof steps, string[]>(
    steps,
    (items, complete, step) => (complete ? items : items.concat([step])),
    []
  );
}

//Name, headline, about, photo, availability
export function leaderProfileCompletenessSteps(user, includeAuthSteps = true) {
  return {
    ...(includeAuthSteps && {
      "Sign up": true
    }),
    "Tell us your name": !!(user && user.name),
    "Write a headline": !!(user && user.details && user.details.headline),
    "Fill out 'About Me'": !!(user && user.details && user.details.about),
    "Tell us your phone number": !!(user && user.phone),
    [`Choose a ${user && isSellerOrg(user) ? "logo image" : "photo"}`]: !!(
      user && user.photo
    ),
    "Record an intro video": !!(user && user.video),
    "Tell us your location": !!(user && hasLocation(user)),
    "Verify your email address": !!(user && user.confirmed_at)
  };
}

export function leaderProfileCompletenessPercent(
  user,
  includeAuthSteps = true
) {
  const steps = leaderProfileCompletenessSteps(user, includeAuthSteps);
  const stepValues = _.values(steps);

  return ~~((_.compact(stepValues).length * 100) / stepValues.length);
}

export function leaderProfileCompletenessItems(user) {
  const steps = leaderProfileCompletenessSteps(user);

  return _.reduce<typeof steps, string[]>(
    steps,
    (items, complete, step) => (complete ? items : items.concat([step])),
    []
  );
}

/**
 * @deprecated use @outschool/user
 */
export function holdMessagesFrom(user) {
  return (
    (user && user.details && user.details.holdMessages) || isSuspended(user)
  );
}

/**
 * @deprecated use @outschool/user
 */
export function isSuspended(user) {
  return Boolean(user && user.suspended_at);
}

export function timePreferenceDescription(user) {
  const ranges = preferredTimeRanges(user);
  let result =
    joinCommasAnd(ranges)?.toLowerCase() ??
    "school hours, after school, and weekend";
  result = result.replace("weekend", "over the weekend");
  return result;
}

export function hasPayoutEmail(user) {
  return !!user.payout_email;
}

export function hasValidPayoutEmail(user) {
  return hasPayoutEmail(user) && isValidEmail(user.payout_email);
}

export function isCharterSchoolCreatedUser(user) {
  return Boolean(
    user && user.details && !!user.details.createdByCharterAdminUid
  );
}

export function needsCharterWelcomeEmail(user) {
  return Boolean(
    user && user.details && !!user.details.needsCharterWelcomeEmail
  );
}

export function parentJoinWizardStepValues(currentStep, currentUser) {
  let steps = ["age", "times"];
  const skipAgeStep =
    currentUser &&
    currentUser.children &&
    currentUser.children.length &&
    currentUser.children.some(c => c.name && c.name.trim() !== "");
  if (skipAgeStep) {
    steps = steps.filter(s => s !== "age");
  }

  const currentStepIndex = steps.indexOf(currentStep);
  const currentStepNumber = currentStepIndex + 1;
  const totalSteps = steps.length;
  const previousStep =
    currentStepIndex > 0 ? steps[currentStepIndex - 1] : undefined;
  const nextStep =
    currentStepIndex < totalSteps - 1 ? steps[currentStepIndex + 1] : undefined;
  return { currentStepNumber, totalSteps, previousStep, nextStep };
}

export function isApprovedTeacher(user) {
  return Boolean(
    user &&
      (user.teacher_approved_at ||
        user.teacher_for_seller_org_user_uid ||
        (user.sellerOrg && user.sellerOrg.currentUserIsTeacher))
  );
}

export function hasTeacherApplication(user) {
  return (
    !!user &&
    !isApprovedTeacher(user) &&
    user.latestTeacherApplication &&
    user.latestTeacherApplication.status === "created"
  );
}

export function unreadConversationsCount(user) {
  if (user) {
    return (
      user.unreadMessageThreadsCount +
      user.unreadPrivateClassMessageThreadsCount
    );
  } else {
    return 0;
  }
}

export function isOrgTeacher(
  user:
    | { teacher_for_seller_org_user_uid: any }
    | { sellerOrg: { currentUserIsTeacher: boolean | null } | null }
) {
  return Boolean(
    user &&
      (("teacher_for_seller_org_user_uid" in user &&
        user.teacher_for_seller_org_user_uid) ||
        ("sellerOrg" in user &&
          user.sellerOrg &&
          user.sellerOrg.currentUserIsTeacher))
  );
}

export function isSellerOrg(user) {
  return Boolean(
    user &&
      (user.owner_of_seller_org_uid ||
        user.ownerOfSellerOrg ||
        (user.sellerOrg && user.sellerOrg.currentUserIsOwner))
  );
}

export function isMemberOfSellerOrg(user) {
  return isSellerOrg(user) || isOrgTeacher(user);
}

export function hasConfirmedEmailForPricingOffer(user, pricingOffer) {
  if (!user || !pricingOffer) {
    return false;
  }
  return (
    (user.confirmed_at &&
      user.email?.toLowerCase() ===
        pricingOffer.userOfferEmail?.toLowerCase()) ||
    user.otherEmailDetails.some(
      emailDetail =>
        emailDetail.email &&
        emailDetail.confirmed_at &&
        pricingOffer.userOfferEmail?.toLowerCase() ===
          emailDetail.email?.toLowerCase()
    )
  );
}

export function hasUnconfirmedEmailForPricingOffer(user, pricingOffer) {
  if (!user || !pricingOffer) {
    return false;
  }

  return (
    (!user.confirmed_at &&
      user.email?.toLowerCase() ===
        pricingOffer.userOfferEmail?.toLowerCase()) ||
    user.otherEmailDetails.some(
      emailDetail =>
        emailDetail.email &&
        !emailDetail.confirmed_at &&
        pricingOffer.userOfferEmail?.toLowerCase() ===
          emailDetail.email?.toLowerCase()
    ) ||
    // PA Jan 13 2021: Short term for School Relief
    // Learner email will only be returned as userOfferEmail if pricingOffer is included in SchoolReliefProgram Feature Flag
    // (see app/server/tasks/PricingOfferTasks.ts findMatchingEmailForUserPricingOffer)
    user.children?.some(learner => {
      return (
        learner.email &&
        !learner.email_confirmed_at &&
        learner.email?.toLowerCase() ===
          pricingOffer.userOfferEmail?.toLowerCase()
      );
    })
  );
}

/*
  returns primary email if it is not confirmed, or an "other" unconfirmed email if was referenced via a pricing offer
*/
export function getEmailToConfirmToUseCredit(user, pricingOffer) {
  const hasUnconfirmedPricingOfferEmail = hasUnconfirmedEmailForPricingOffer(
    user,
    pricingOffer
  );

  if (!user || (user.confirmed_at && !hasUnconfirmedPricingOfferEmail)) {
    return null;
  }

  return !user.confirmed_at
    ? user.email?.toLowerCase()
    : pricingOffer?.userOfferEmail?.toLowerCase();
}

export function standardNormalize(row) {
  return row && { ...row, email: normalizeString(row.email) };
}

export function getTotalBufferMinutes(buffer: any): number {
  if (!buffer) {
    return 0;
  }

  const hours = buffer.hours ? buffer.hours : 0;
  const minutes = buffer.minutes ? buffer.minutes : 0;

  return hours * 60 + minutes;
}
