import { gql, graphql } from "@outschool/ui-apollo";

import EnrollmentFragment from "./EnrollmentFragment";
import EnrollmentMeetingPurchaseFragment from "./EnrollmentMeetingPurchaseFragment";
import EnrollmentSummaryFragment from "./EnrollmentSummaryFragment";
import GiftEnrollmentFragment from "./GiftEnrollmentFragment";

// Mutation query string
export const transferEnrollmentMutation = gql`
  mutation TransferEnrollment($enrollmentUid: ID!, $newSectionUid: ID!) {
    transferEnrollment(
      enrollmentUid: $enrollmentUid
      newSectionUid: $newSectionUid
    ) {
      ...EnrollmentFragment
      section {
        uid
      }
    }
  }
  ${EnrollmentFragment}
`;

// Mutation query string
export const transferUnclaimedGiftEnrollmentMutation = gql`
  mutation TransferUnclaimedGiftEnrollment(
    $enrollmentUid: ID!
    $newSectionUid: ID!
  ) {
    transferUnclaimedGiftEnrollment(
      enrollmentUid: $enrollmentUid
      newSectionUid: $newSectionUid
    ) {
      ...GiftEnrollmentFragment
      section {
        uid
      }
    }
  }
  ${GiftEnrollmentFragment}
`;

// React component wrapper
export const transferEnrollmentMutationWrapper = graphql(
  transferEnrollmentMutation,
  {
    props: ({ mutate }) => ({
      transferEnrollment: (
        enrollmentUid: $TSFixMe,
        newSectionUid: $TSFixMe
      ) => {
        return mutate!({
          variables: { enrollmentUid, newSectionUid }
        });
      }
    })
  }
);

export const withdrawAndRefundGiftEnrollmentMutation = gql`
  mutation WithdrawAndRefundGiftEnrollment(
    $enrollmentUid: ID!
    $refundReason: String!
    $refundNote: String!
    $refundAllRemaining: Boolean
  ) {
    withdrawAndRefundGiftEnrollment(
      enrollmentUid: $enrollmentUid
      refundReason: $refundReason
      refundNote: $refundNote
      refundAllRemaining: $refundAllRemaining
    ) {
      section {
        uid
        currentUserGiftEnrollments {
          ...GiftEnrollmentFragment
        }
      }
    }
  }
  ${GiftEnrollmentFragment}
`;

export const withdrawAndRefundEnrollmentMutation = gql`
  mutation WithdrawAndRefundEnrollment(
    $enrollmentUid: ID!
    $refundReason: String!
    $refundNote: String!
    $refundAllRemaining: Boolean
  ) {
    withdrawAndRefundEnrollment(
      enrollmentUid: $enrollmentUid
      refundReason: $refundReason
      refundNote: $refundNote
      refundAllRemaining: $refundAllRemaining
    ) {
      ...EnrollmentFragment
      section {
        uid
      }
    }
  }
  ${EnrollmentFragment}
`;

export const withdrawAndRefundEnrollmentMutationWrapper = graphql(
  withdrawAndRefundEnrollmentMutation,
  {
    props: ({ mutate }) => ({
      withdrawAndRefundEnrollment: (variables: $TSFixMe) =>
        mutate!({ variables })
    })
  }
);

export const stopSubscriptionMutation = gql`
  mutation StopSubscription(
    $enrollmentUid: ID!
    $reason: String
    $note: String
    $refundPaymentUids: [ID!]
  ) {
    stopSubscription(
      enrollmentUid: $enrollmentUid
      reason: $reason
      note: $note
      refundPaymentUids: $refundPaymentUids
    ) {
      ...EnrollmentFragment
      section {
        uid
      }
    }
  }
  ${EnrollmentFragment}
`;

export const stopSubscriptionMutationWrapper = graphql(
  stopSubscriptionMutation,
  {
    props: ({ mutate }) => ({
      stopSubscription: (variables: $TSFixMe) => mutate!({ variables })
    })
  }
);

export const createEnrollmentsMutation = gql`
  mutation CreateEnrollments($orderInput: OrderInput!, $esaState: String) {
    createEnrollments(orderInput: $orderInput, esaState: $esaState) {
      orderSlugId
      enrollments {
        pricingOffer {
          uid
        }
        ...EnrollmentFragment
      }
      giftEnrollments {
        uid
        acceptGiftSlugId
        confirmedAt
      }
    }
  }
  ${EnrollmentFragment}
`;

export const createEnrollmentMeetingPurchaseMutation = gql`
  mutation CreateEnrollmentMeetingPurchase(
    $orderInput: EnrollmentMeetingPurchaseOrderInput!
    $esaState: String
  ) {
    createEnrollmentMeetingPurchase(
      orderInput: $orderInput
      esaState: $esaState
    ) {
      orderSlugId
      enrollmentMeetingPurchase {
        ...EnrollmentMeetingPurchaseFragment
      }
    }
  }
  ${EnrollmentMeetingPurchaseFragment}
`;

export const createBulkEnrollmentsMutation = gql`
  mutation CreateBulkEnrollments(
    $bulkOrderInput: BulkOrderInput!
    $esaState: String
  ) {
    createBulkEnrollments(
      bulkOrderInput: $bulkOrderInput
      esaState: $esaState
    ) {
      orderSlugId
      enrollments {
        pricingOffer {
          uid
        }
        ...EnrollmentFragment
      }
      giftEnrollments {
        uid
        acceptGiftSlugId
        confirmedAt
      }
      enrollmentMeetingPurchases {
        ...EnrollmentMeetingPurchaseFragment
      }
    }
  }
  ${EnrollmentFragment}
  ${EnrollmentMeetingPurchaseFragment}
`;

export const claimGiftEnrollmentMutation = gql`
  mutation ClaimGiftEnrollment(
    $acceptGiftSlugId: ID!
    $learner: EnrollmentLearnerInput!
    $recipientWillPurchaseOngoingExtension: Boolean
  ) {
    claimGiftEnrollment(
      acceptGiftSlugId: $acceptGiftSlugId
      learner: $learner
      recipientWillPurchaseOngoingExtension: $recipientWillPurchaseOngoingExtension
    ) {
      ...EnrollmentFragment
    }
  }

  ${EnrollmentFragment}
`;

export const createClubEnrollmentMutation = gql`
  mutation CreateClubEnrollment($sectionUid: ID!, $learnerUid: ID!) {
    createClubEnrollment(sectionUid: $sectionUid, learnerUid: $learnerUid) {
      ...EnrollmentFragment
    }
  }

  ${EnrollmentFragment}
`;

export const pauseOngoingEnrollmentsMutation = gql`
  mutation PauseOngoingEnrollments($input: [PauseOngoingEnrollmentInput!]!) {
    pauseOngoingEnrollments(input: $input) {
      ...EnrollmentFragment
    }
  }

  ${EnrollmentFragment}
`;

export const unpauseOngoingEnrollmentsPausedForPaymentFailureMutation = gql`
  mutation UnpauseOngoingEnrollmentsPausedForPaymentFailure(
    $input: UnpauseOngoingEnrollmentsPausedForPaymentFailureInput!
  ) {
    unpauseOngoingEnrollmentsPausedForPaymentFailure(input: $input) {
      updatedEnrollments {
        ...EnrollmentSummaryFragment
      }
      paidEnrollments {
        ...EnrollmentSummaryFragment
      }
      unsubscribedEnrollments {
        ...EnrollmentSummaryFragment
      }
    }
  }

  ${EnrollmentSummaryFragment}
`;

export const oneOnOneEnrollmentRepriceMutation = gql`
  mutation OneOnOneEnrollmentRepriceMutation(
    $enrollmentUid: ID!
    $newPriceCents: Int!
  ) {
    repriceTutoringEnrollment(
      enrollmentUid: $enrollmentUid
      newPriceCents: $newPriceCents
    ) {
      enrollment {
        ...EnrollmentFragment
      }
      canceledMeetingUids
    }
  }
  ${EnrollmentFragment}
`;
