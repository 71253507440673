let ActionType = {
  User: {
    CHANGE_PASSWORD: "",
    CONFIRM_EMAIL: "",
    LOCATION_TO_TIME_ZONE: "",
    LOGGED_IN: "",
    LOGIN: "",
    REFRESH: "",
    UPDATE: "",
    CURRENT_USER_UPDATED: "",
    REDIRECT_TO_LEARNER_APP: ""
  },

  Payment: {
    CARD_OBTAINED: "",
    CREATE: "",
    APPLE_PAY_AVAILABLE: "",
    APPLE_PAY_SHOWN: "",
    APPLE_PAY_CLICKED: "",
    APPLE_PAY_USED: "",
    CARD_CONFIRMED: "",
    CARD_CONFIRMATION_ATTEMPT: ""
  },

  Webapp: {
    CHECK_INTERCOM: "",
    SET_TOP_NOTICE: ""
  }
};

// Populate ActionType values, e.g. ActionType.Activity.SELECT = 'Activity.SELECT'.
Object.keys(ActionType).forEach(category => {
  Object.keys(ActionType[category]).forEach(actionType => {
    ActionType[category][actionType] = category + "." + actionType;
  });
});

export default ActionType;
