import { Box, Button } from "@outschool/backpack";
import { getFundingProgramProperties } from "@outschool/funding-programs-shared";
import { useTranslation } from "@outschool/localization";
import { useSession } from "@outschool/ui-auth";
import { Banner } from "@outschool/ui-legacy-component-library";
import React from "react";

type Props = {
  onExitSession: () => void;
  esaState?: string;
};

export default function EsaBanner({ onExitSession }: Props) {
  const { t } = useTranslation("client\\components\\banners\\EsaBanner");
  const { esaSessionData } = useSession();
  const fundingProgramProperties = getFundingProgramProperties(
    esaSessionData?.shipping?.state
  );
  const isClassWalletProgram = fundingProgramProperties?.isClassWallet;
  const fundingProgramName = fundingProgramProperties?.programName;

  if (!isClassWalletProgram || !fundingProgramName) {
    return null;
  }

  return (
    <Banner data-test-id="esa-banner">
      <Box
        flex
        sx={{
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {t(
          "Use your ClassWallet funds to enroll in {{fundingProgramName}} eligible classes.",
          { fundingProgramName }
        )}
        &nbsp;
        <Button
          variant="link"
          sx={{
            fontSize: "1em"
          }}
          onClick={onExitSession}
        >
          {t("Exit ClassWallet Session")}
        </Button>
        &nbsp;
        {t("to pay by other methods.")}
      </Box>
    </Banner>
  );
}
