import { Box, Icon, SxProps, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { fasExclamationTriangle } from "@outschool/icons";
import {
  renderCurrencyString as renderBaseCurrencyString,
  useLocalPriceDisplay
} from "@outschool/localization";
import React from "react";

export type PriceDisplayProps = {
  amountCents: number | "-";
  periodText?: string;
  dataTestId?: string;
  showPlus?: boolean;
  showWarning?: boolean;
  useFree?: boolean;
};

const PRICE_SX: SxProps = {
  minWidth: "90px",
  textAlign: "right"
};

export function PriceDisplay({
  amountCents,
  periodText,
  dataTestId,
  showPlus = false,
  showWarning = false,
  useFree = true
}: PriceDisplayProps) {
  const { isBaseCurrency, renderCurrencyString } = useLocalPriceDisplay();

  function renderCurrencyStringWithUsdEquivalent(amountCentsInUsd: number) {
    const localCurrencyString = renderCurrencyString({
      priceInCents: amountCentsInUsd,
      useFree,
      showCurrencyCode: false
    });
    if (amountCentsInUsd === 0 && useFree) {
      return <>{localCurrencyString}</>;
    } else {
      return (
        <>
          {localCurrencyString}{" "}
          <Typography
            variant="inherit"
            sx={{
              color: "grey.500"
            }}
          >
            ({showPlus && "+"}
            {renderBaseCurrencyString({
              priceInCents: amountCentsInUsd,
              useBaseCurrency: true,
              showCurrencyCode: false
            })}
            )
          </Typography>
        </>
      );
    }
  }

  return (
    <Box sx={PRICE_SX} data-test-id={dataTestId}>
      {amountCents === "-" ? (
        amountCents
      ) : (
        <>
          {showWarning && (
            <>
              <Icon
                icon={fasExclamationTriangle}
                sx={{
                  color: "#B28B1E"
                }}
              />{" "}
            </>
          )}
          {showPlus && "+"}
          {isBaseCurrency
            ? renderCurrencyString({
                priceInCents: amountCents,
                useFree,
                showCurrencyCode: false
              })
            : renderCurrencyStringWithUsdEquivalent(amountCents)}
          {periodText && <Typography variant="body1"> {periodText}</Typography>}
        </>
      )}
    </Box>
  );
}
