import { CreditAccountStatus } from "@outschool/gql-backend-generated";
import {
  Trans,
  renderCurrencyString,
  useTranslation
} from "@outschool/localization";
import { useSession } from "@outschool/ui-auth";
import {
  KansasKeepFundingBanner,
  useTopNoticeContext
} from "@outschool/ui-components-website";
import React from "react";

import {
  getEmailToConfirmToUseCredit,
  hasUnconfirmedEmailForPricingOffer
} from "../../shared/User";
import { useResendEmailConfirmation } from "../hooks/CurrentUser";
import { useCreditAccountCached } from "../queries/CreditQueries";
import EmailBanner from "./banners/EmailBanner";
import EsaBanner from "./banners/EsaBanner";

export default function AccountBanner() {
  const {
    currentUser,
    currentUserHasLoaded,
    isLoggedIn,
    isEsaSession,
    destroyEsaSession
  } = useSession();
  const { setTimedTopNotice } = useTopNoticeContext();
  const isUserInKansas = currentUser?.location?.state === "Kansas";
  const { creditAccount, loading: creditAccountLoading } =
    useCreditAccountCached(false);
  const { t } = useTranslation("client\\components\\AccountBanner");
  const hasAnUnconfirmedEmailForPricingOffer =
    hasUnconfirmedEmailForPricingOffer(currentUser, currentUser?.pricingOffer);

  const emailToConfirm = getEmailToConfirmToUseCredit(
    currentUser,
    currentUser?.pricingOffer
  );
  const resendEmail = useResendEmailConfirmation(currentUser, emailToConfirm);

  const shouldShowEmail =
    currentUserHasLoaded &&
    !creditAccountLoading &&
    isLoggedIn &&
    currentUser &&
    emailToConfirm !== null;

  if (currentUser?.email_bounced_at) {
    const currentUserEmail = currentUser.email;
    return (
      <EmailBanner>
        <Trans t={t}>
          {{ currentUserEmail }} is rejecting some emails from Outschool. Please
          contact support at{" "}
          <a href="mailto:support@outschool.com">support@outschool.com</a>.
        </Trans>
      </EmailBanner>
    );
  } else if (shouldShowEmail) {
    let message = t(
      "Please confirm your email address by clicking the link we sent to {{emailToConfirm}}",
      { emailToConfirm }
    );
    if (
      currentUser?.pricingOffer &&
      hasAnUnconfirmedEmailForPricingOffer &&
      !currentUser?.pricingOffer?.isFinancialAid
    ) {
      const buyerOrgName = currentUser.pricingOffer?.buyerOrgName;
      message = t(
        "To use {{buyerOrgName}} funds, please click on the confirmation link we emailed to {{emailToConfirm}}.",
        { buyerOrgName, emailToConfirm }
      );
    } else if (
      // @ts-ignore TS(2532): Object is possibly 'undefined'.
      creditAccount?.balance > 0 &&
      creditAccount?.status ===
        CreditAccountStatus.RequiresConfirmedPrimaryEmail
    ) {
      const currencyString = renderCurrencyString({
        priceInCents: creditAccount.balance,
        showCurrencyCode: true
      });
      message = t(
        "To use your {{currencyString}} in Outschool Credits, please confirm your email address by clicking the link we sent to {{emailToConfirm}}. ",
        { currencyString, emailToConfirm }
      );
    }

    return (
      <EmailBanner
        showResendButton
        onResendButtonClick={async () => {
          await resendEmail();
          setTimedTopNotice(
            t("A new confirmation email was sent to {{emailToConfirm}}.", {
              emailToConfirm
            })
          );
        }}
      >
        {message}
      </EmailBanner>
    );
  } else if (isUserInKansas) {
    return <KansasKeepFundingBanner />;
  } else if (isEsaSession) {
    return <EsaBanner onExitSession={destroyEsaSession} />;
  } else {
    return null;
  }
}
