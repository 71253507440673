import { Box, Icon, Typography, styled } from "@outschool/backpack";
import { faCalendarRange, faComment, faHeart, faHome } from "@outschool/icons";
import { I18nLocale, useLocale, useTranslation } from "@outschool/localization";
import {
  learnerPlanPath,
  parentProfilePath,
  savedClassesPath
} from "@outschool/routes";
import { useSession } from "@outschool/ui-auth";
import { UserHeadshotImage } from "@outschool/ui-components-shared";
import { useImpressionTracking } from "@outschool/ui-legacy-component-library";
import React from "react";
import { NavLink } from "react-router-dom";

import * as Routes from "../../../shared/Routes";
import * as User from "../../../shared/User";
import { UserMode } from "../../lib/UserMode";
import UnreadMessagesCount from "../UnreadMessagesCount";

export const Divider = () => {
  return (
    <Box
      sx={() => ({
        borderStyle: "solid",
        borderColor: "neutral.200",
        borderWidth: "0 1px 0 0",
        height: 50,
        marginTop: "auto",
        marginBottom: "auto",
        width: "1px"
      })}
    />
  );
};

const StyledLink = styled(NavLink)(({ theme }) => {
  const userSelectedLocale = useLocale();
  return {
    position: "relative",
    textDecoration: "none",
    width:
      userSelectedLocale && userSelectedLocale === I18nLocale.Ja
        ? "60px"
        : "48px",
    color: theme.palette.grey[500],
    paddingTop: 5,
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "&:hover:not(:disabled):not(:[aria-current='page'])": {
      color: theme.palette.grey[500],
      textDecoration: "none"
    },
    ["&[aria-current='page']"]: {
      color: theme.palette.primary.main,
      border: 0,
      borderBottom: 2,
      borderStyle: "solid",
      borderColor: theme.palette.primary.main
    },
    "&:hover svg": {
      color: theme.palette.primary.main
    }
  };
});

const StyledIcon = styled(Icon)(({ theme }) => ({
  fontSize: "1.5em",
  color: theme.palette.grey[500],
  ["[aria-current='page'] &"]: { color: theme.palette.primary.main }
}));

export function DesktopNavTabs() {
  const { t } = useTranslation("client\\components\\nav\\DesktopHeaderNav");
  const { currentUser, isLeader } = useSession();

  const isOnOwnProfile = currentUser
    ? // eslint-disable-next-line no-restricted-globals
      location.pathname.includes("/parents/" + currentUser.uid)
    : false;
  return (
    <Box flex sx={{ gap: 8 }}>
      <DesktopNavTab
        to={"/"}
        trackingName={"home"}
        isActive={(_, location) => {
          return (
            !location.pathname.includes("/search") &&
            !location.pathname.includes("/learn/planner") &&
            !location.pathname.includes("/account/saved-classes") &&
            !isOnOwnProfile &&
            !location.pathname.includes("/conversations") &&
            !location.pathname.includes("/account/parent")
          );
        }}
        icon={<StyledIcon icon={faHome} />}
      >
        {t("Home")}
      </DesktopNavTab>
      <DesktopNavTab
        to={savedClassesPath()}
        trackingName={"favorites"}
        icon={<StyledIcon icon={faHeart} />}
      >
        {t("Favorites")}
      </DesktopNavTab>
      <DesktopNavTab
        to={learnerPlanPath()}
        trackingName={"planner"}
        icon={<StyledIcon icon={faCalendarRange} />}
      >
        {t("Planner")}
      </DesktopNavTab>
      <DesktopNavTab
        to={Routes.conversationsPath(
          isLeader ? UserMode.Educator : UserMode.Parent
        )}
        trackingName={"messages"}
        icon={<StyledIcon icon={faComment} />}
      >
        {t("Messages")}
        <UnreadMessagesCount
          color="red"
          count={User.unreadConversationsCount(currentUser)}
          sx={{ position: "absolute", right: 0, top: 0 }}
        />
      </DesktopNavTab>
      <DesktopNavTab
        isActive={(_, location) => {
          return (
            isOnOwnProfile || location.pathname.includes("/account/parent")
          );
        }}
        to={parentProfilePath(currentUser?.uid || "")}
        trackingName={"profile"}
        icon={
          <Box sx={{ position: "relative" }}>
            <UserHeadshotImage size={24} user={currentUser} />
            {isOnOwnProfile && (
              <Box
                sx={() => ({
                  position: "absolute",
                  width: 26,
                  height: 26,
                  border: `2px solid ${"primary.main"}`,
                  borderRadius: "50%",
                  top: -1,
                  left: -1
                })}
              />
            )}
          </Box>
        }
      >
        {t("Profile")}
      </DesktopNavTab>
    </Box>
  );
}

function DesktopNavTab({
  icon,
  children,
  to,
  trackingName,
  onClick,
  ...props
}: {
  icon: React.ReactNode;
  children: React.ReactNode;
  trackingName: string;
  onClick?: () => void;
} & React.ComponentProps<typeof NavLink>) {
  const { trackTouch } = useImpressionTracking({
    trackingLabel: trackingName,
    uniqueId: `${trackingName}_mobile_footer_nav`,
    trackingEventName: `${trackingName}_mobile_footer_nav_event`
  });

  return (
    <StyledLink
      to={to}
      {...props}
      onClick={() => {
        trackTouch();
        if (onClick) {
          onClick();
        }
      }}
    >
      {icon}
      <Typography
        variant="body2"
        sx={{
          fontSize: "1rem",
          lineHeight: "1.8rem"
        }}
      >
        {children}
      </Typography>
    </StyledLink>
  );
}
