import { EventEmitter } from "events";

export default class BaseStore extends EventEmitter {
  _dispatchToken: unknown;

  subscribe(appDispatcher, actionSubscribe) {
    this._dispatchToken = appDispatcher.dispatcher.register(actionSubscribe());
  }

  unsubcribe(appDispatcher) {
    appDispatcher.dispatcher.unregister(this._dispatchToken);
  }

  get dispatchToken() {
    return this._dispatchToken;
  }

  emitChange() {
    this.emit("CHANGE");
  }

  addChangeListener(cb) {
    this.on("CHANGE", cb);
  }

  removeChangeListener(cb) {
    this.removeListener("CHANGE", cb);
  }

  logChangeListeners(prefix) {
    //@ts-expect-error: accessing private api
    let events = this._events;
    if (!events.CHANGE) {
      console.log(prefix, "AppState change listeners: none");
    } else if (typeof events.CHANGE === "function") {
      console.log(prefix, "AppState change listeners:", events.CHANGE.boundTo);
    } else {
      console.log(
        prefix,
        "AppState listeners:",
        events.CHANGE.map(cb => cb.boundTo).join(", ")
      );
    }
  }
}
