import { OrganizationType } from "@outschool/gql-backend-generated";
import { CurrentUserQueryQuery } from "@outschool/gql-frontend-generated";

import * as Routes from "../../shared/Routes";
import * as User from "../../shared/User";
import { isOrganizationAdmin } from "./Users";

export enum OrgAdminType {
  School = "School",
  Employer = "Employer",
  Partner = "Partner"
}

type AdminRoutes = {
  homeRoute: string;
  analyticsRoute: string;
};

export function getBuyerOrgAdminType(
  currentUser: CurrentUserQueryQuery["currentUser"]
): OrgAdminType | null {
  const organizationType = currentUser?.organizationAdmin?.organization?.type;
  switch (organizationType) {
    case OrganizationType.School:
    case OrganizationType.CharterSchool:
    case OrganizationType.EsaOrMicrogrant:
      return OrgAdminType.School;
    case OrganizationType.Company:
      return OrgAdminType.Employer;
    case OrganizationType.CommunityPartner:
    case OrganizationType.OrgCommunityPartner:
      return OrgAdminType.Partner;
    default:
      return null;
  }
}

export function getOrgAdminRoutes(
  orgAdminType: OrgAdminType | null
): AdminRoutes {
  switch (orgAdminType) {
    case OrgAdminType.School:
      return {
        homeRoute: Routes.schoolAdminHomepage(),
        analyticsRoute: Routes.schoolAnalyticsPath()
      };
    case OrgAdminType.Employer:
      return {
        homeRoute: Routes.employerAdminHomepage(),
        analyticsRoute: Routes.employerAnalyticsPath()
      };
    case OrgAdminType.Partner:
      return {
        homeRoute: Routes.partnerAdminHomepage(),
        analyticsRoute: Routes.partnerAnalyticsPath()
      };
    default:
      return { homeRoute: "", analyticsRoute: "" };
  }
}

export function getOrgAdminRoutesFromUser(
  currentUser: CurrentUserQueryQuery["currentUser"]
): AdminRoutes {
  const buyerOrgAdminType = getBuyerOrgAdminType(currentUser);
  return getOrgAdminRoutes(buyerOrgAdminType);
}

export function isBuyerOrgAdmin(
  currentUser: CurrentUserQueryQuery["currentUser"]
) {
  return !User.isAdmin(currentUser) && isOrganizationAdmin(currentUser);
}
