import { Box } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { FieldErrorMessage } from "@outschool/ui-components-shared";
import { useFormState } from "@outschool/ui-utils";
import React from "react";

import { StripePaymentElementPaymentMethod } from "../../../shared/Stripe";
import { StripeCardBuyerName } from "../lib/Stripe";
import { BillingDetails } from "./Stripe";
import StripePaymentElement from "./StripePaymentElement";

const ROW_HEIGHT = "88px";

const LabelBox = ({ children }: $TSFixMe) => (
  <Box
    sx={{
      fontWeight: "fontWeightMedium",
      fontSize: "0.875em",
      lineHeight: "21px",
      color: "grey.700",
      marginBottom: "0.5em"
    }}
  >
    {children}
  </Box>
);

export type StripeNewCardProps = {
  isGuestCheckout?: boolean;
  setIsCardComplete(isCardComplete: boolean): void;
  setBillingDetails(billingDetailsToUpdate: BillingDetails): void;
};

export default function StripeNewCard({
  setIsCardComplete,
  setBillingDetails,
  isGuestCheckout = false
}: StripeNewCardProps) {
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const { fields, setters, validateAll, errors } = useFormState(
    {
      name: {
        value: "",
        validators: isGuestCheckout
          ? [value => (value.length > 0 ? null : "Full name is required.")]
          : []
      }
    },
    { validateInitialValues: false }
  );

  const [innerCardComplete, setInnerCardComplete] = React.useState(false);

  React.useEffect(() => {
    if (hasLoaded) {
      setIsCardComplete(innerCardComplete && !validateAll().hasErrors);
      const billingDetails = {};
      if (isGuestCheckout) {
        billingDetails["name"] = fields.name.value;
      }
      setBillingDetails(billingDetails);
    } else {
      setHasLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.name.value, innerCardComplete]);

  const { t } = useTranslation("client\\components\\payments\\StripeNewCard");
  const [paymentMethod, setPaymentMethod] = React.useState(
    StripePaymentElementPaymentMethod.card
  );

  return (
    <React.Fragment>
      {isGuestCheckout && (
        <Box
          sx={{
            height: ROW_HEIGHT
          }}
        >
          <LabelBox>{t`Full Name`}</LabelBox>
          <StripeCardBuyerName
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setters.name(e.target.value)
            }
            value={fields.name.value}
          />
          {/* @ts-ignore TS(2322): Type 'FieldError | undefined' is not assignable to... Remove this comment to see the full error message */}
          <FieldErrorMessage value={errors.name?.[0]} />
        </Box>
      )}
      <StripePaymentElement
        setIsPaymentElementComplete={setInnerCardComplete}
        setPaymentMethod={setPaymentMethod}
        paymentMethod={paymentMethod}
        payingUser={undefined}
      />
    </React.Fragment>
  );
}
