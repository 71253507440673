import { Box, Theme } from "@outschool/backpack";
import { ApolloError } from "@outschool/ui-apollo";
import {
  ContentfulAnnouncementBanner,
  ContentfulAnnouncementBannerItem,
  PageAlerts,
  ReadOnlyBanner,
  useContentfulAnnouncementBannerHook
} from "@outschool/ui-components-website";
import React, { useEffect, useState } from "react";

import * as Env from "../../../shared/Env";
import SectionStartReminder from "../../containers/SectionStartReminder";
import { UserMode, UserModeProvider } from "../../lib/UserMode";
import { UnlimitedCouponBanner } from "../../routes/Subscriptions/components/UnlimitedCouponBanner";
import { useHasFirstMonthOffCouponApplied } from "../../routes/Subscriptions/hooks/useStripeCoupons";
import AccountBanner from "../AccountBanner";
import Footer from "../Footer";
import { GiftCardModal } from "../giftCards/GiftCardModal";
import Header from "../nav/Header";
import { MobileFooterTabBar } from "../nav/MobileFooterTabBar";
import ReviewReminderTopNoticeBar from "../ReviewReminderTopNoticeBar";
import SignupModal from "../SignupModal";
import TimedTopNoticeBar from "../TimedTopNoticeBar";
import TopNoticeBar from "../TopNoticeBar";

export type SimpleLayoutProps = {
  children?: React.ReactNode;
  error?: string | ApolloError;
  narrowColumn?: boolean;
  narrowPaddedColumn?: boolean;
  grayBackground?: boolean;
  hideFooter?: boolean;
  hideReviewReminder?: boolean;
  hideAccountBanner?: boolean;
  sx?: React.CSSProperties;
  contentSx?: React.CSSProperties;
  userMode?: UserMode;
  allowGlobalNavbar?: boolean;
  hideSignUpModal?: boolean;
  showSearchBox?: boolean;
  hideHeaderRight?: boolean;
  customHeader?: React.ReactNode;
};

const SimpleLayout = React.forwardRef<HTMLDivElement, SimpleLayoutProps>(
  (
    {
      children,
      error,
      narrowColumn,
      narrowPaddedColumn,
      grayBackground,
      customHeader,
      hideFooter = false,
      hideReviewReminder = false,
      hideAccountBanner = false,
      sx = {},
      contentSx = {},
      userMode = null,
      allowGlobalNavbar = false,
      hideSignUpModal = false,
      showSearchBox = true,
      hideHeaderRight = false
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [contentfulBannerData, setContentfulBannerData] =
      useState<ContentfulAnnouncementBannerItem>(null);
    const bannerData = useContentfulAnnouncementBannerHook();
    useEffect(() => {
      setContentfulBannerData(bannerData as ContentfulAnnouncementBannerItem);
    }, [bannerData]);
    const mobileFooterRef = React.useRef<HTMLElement>(null);
    const bottomOffset =
      mobileFooterRef.current?.getBoundingClientRect().height ?? 0;
    const showFirstMonthOffBanner = useHasFirstMonthOffCouponApplied();

    return (
      <UserModeProvider value={userMode}>
        <Box>
          {!!customHeader ? (
            customHeader
          ) : (
            <Header
              allowGlobalNavbar={allowGlobalNavbar}
              showSearchBox={showSearchBox}
              hideHeaderRight={hideHeaderRight}
            />
          )}
          <TopNoticeBar topNotice={error} />
          <TimedTopNoticeBar />
          {Env.IS_READ_ONLY_MODE && <ReadOnlyBanner />}
          <ContentfulAnnouncementBanner bannerData={contentfulBannerData} />
          {!hideAccountBanner && <AccountBanner />}
          <SectionStartReminder />
          {!hideReviewReminder && <ReviewReminderTopNoticeBar />}
          <PageAlerts />
        </Box>

        {showFirstMonthOffBanner && <UnlimitedCouponBanner />}

        <Box
          sx={[
            {
              minHeight: "100%",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              ...((grayBackground || narrowColumn || narrowPaddedColumn) && {
                backGroundColor: "#FAFAFA"
              }),
              marginBottom: bottomOffset
            },
            ...(Array.isArray(sx) ? sx : [sx])
          ]}
          ref={ref}
        >
          <Box
            sx={(theme: Theme) => ({
              flexGrow: 1,
              ...(narrowColumn && {
                maxWidth: "750px",
                position: "relative",
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                boxSizing: "border-box",
                backgroundColor: "#FFF"
              }),
              ...(narrowPaddedColumn && {
                maxWidth: "750px",
                position: "relative",
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                boxSizing: "border-box",
                backgroundColor: "#FFF",
                paddingY: "2em",
                paddingX: "1em",
                [theme.breakpoints.up("md")]: {
                  paddingX: "2em"
                }
              }),
              ...contentSx
            })}
          >
            {children}
          </Box>
          <MobileFooterTabBar ref={mobileFooterRef} />
          {!hideFooter && <Footer />}
          {!hideSignUpModal && <SignupModal />}
          <GiftCardModal />
        </Box>
      </UserModeProvider>
    );
  }
);

export default SimpleLayout;
