import {
  ActivitiesRow,
  SectionsRow
} from "@outschool/db-queries/dist/generated/types";
import { addParamsToUrl } from "@outschool/routes";
import _ from "lodash";
import * as uuid from "uuid";

// See http://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
const UUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

// from http://makandracards.com/makandra/1395-simple_format-helper-for-javascript
export function simpleFormat(str: string) {
  str = str.replace(/\r\n?/, "\n");
  str = str.trim();
  if (str.length > 0) {
    str = str.replace(/\n\n+/g, "</p><p>");
    str = str.replace(/\n/g, "<br/>");
    str = "<p>" + str + "</p>";
  }
  return str;
}

export function isUuid(testString: string) {
  return !!UUID_REGEX.exec(testString);
}

export function agePreferencesInSentence(agePrefs: number[]) {
  if (!agePrefs || agePrefs.length === 0) {
    return "all ages";
  } else if (agePrefs.length === 1) {
    return "age " + agePrefs;
  } else if (agePrefs.length === 2) {
    return "ages " + agePrefs[0] + " and " + agePrefs[1];
  } else {
    const last = agePrefs.length - 1;
    return (
      "ages " + agePrefs.slice(0, last).join(", ") + " and " + agePrefs[last]
    );
  }
}

/**
 * This fn must stay in sync w/ lang-js/packages/email-utils/src/utils.ts
 * @deprecated use `@outschool/email-utils`
 */
export function firstNameString(name: string | null | undefined) {
  if (name) {
    return name.split(" ")[0];
  } else {
    return "there";
  }
}

export function removeNameTitle(name: string) {
  const lowercase = name.toLowerCase();

  if (lowercase.indexOf("mr.") !== -1 || lowercase.indexOf("ms.") !== -1) {
    return name.substring(3).trimStart();
  }

  if (lowercase.indexOf("mrs.") !== -1) {
    return name.substring(4).trimStart();
  }

  if (lowercase.indexOf("miss ") !== -1) {
    return name.substring(5).trimStart();
  }

  return name;
}

type LocationParts = {
  placeName?: string;
  address?: string;
  placeDescription?: string;
};

export function locationParts(
  activity: Pick<ActivitiesRow, "location">,
  section: Pick<SectionsRow, "location">
): LocationParts {
  const parts = {};
  ["placeName", "address", "placeDescription"].forEach(partName => {
    parts[partName] =
      (section && section.location && section.location[partName]) ||
      (activity && activity.location && activity.location[partName]);
  });
  return parts;
}

export function activityLocationLines(
  activity: Pick<ActivitiesRow, "location">,
  section: Pick<SectionsRow, "location">
) {
  const location = locationParts(activity, section);
  return _.compact([
    location.placeName,
    location.address,
    location.placeDescription
  ]);
}

export function arrayToObject<T>(arr: T[], hashFunction: (key: T) => string) {
  return arr.reduce<Record<string, T>>((result, a) => {
    result[hashFunction(a)] = a;
    return result;
  }, {});
}

export function createMailtoLink({ subject = "", body = "" }) {
  return addParamsToUrl("mailto:", {
    Subject: subject,
    Body: body
  });
}

export function uuidv4() {
  return uuid.v4();
}

export function splitFirstAndLastName(name: string | null) {
  let firstName: string | null = null;
  let lastName: string | null = null;

  if (name) {
    const names = name.split(" ");
    firstName = names[0];
    if (names.length > 1) {
      lastName = names.slice(1, names.length).join(" ");
    }
  }

  return { firstName, lastName };
}

// fromEntries polyfill
// source: https://github.com/feross/fromentries/blob/master/index.js
export function fromEntries(iterable) {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {});
}
