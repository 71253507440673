import { CurrencyCode } from "@outschool/gql-backend-generated";
import { useLookupIP } from "@outschool/iplookup-client";
import { useLocalStorageState } from "@outschool/local-storage";
import {
  COUNTRIES_INFO,
  CountryCode,
  CurrencyLocalizationContext,
  I18nLocale,
  LocaleProvider,
  SELECTED_CURRENCY_CODE_LOCAL_STORAGE_KEY,
  SetCurrencyLocalizationContext,
  currencyCodeFromString,
  getCountryFromCurrency,
  getCurrencyFromCountryIsoCode,
  getSuggestedCurrencyCodeForLocale,
  useLocale
} from "@outschool/localization";
import { useSession } from "@outschool/ui-auth";
import React from "react";

import { useUpdateCurrentUserMutation } from "../queries/CurrentUser";
import { PseudoLocalizationProvider } from "./PseudoLocalizationProvider";
import { TimeZoneProvider } from "./TimeZoneProvider";

const CurrencyProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const locale = useLocale();
  const {
    currentUser,
    isLoggedIn,
    currentUserIsLoading,
    currentUserHasLoaded
  } = useSession();

  const currentUserDefaultCurrencyCode = currentUser?.default_currency
    ? currencyCodeFromString(currentUser.default_currency)
    : null;
  const [updateCurrentUser] = useUpdateCurrentUserMutation();

  const [selectedCurrencyLocalStorage, setSelectedCurrencyLocalStorage] =
    // Default selected currency from local storage to null so that ipLookup
    // can take precedence when a user is in a different country
    useLocalStorageState<CurrencyCode | null>(
      SELECTED_CURRENCY_CODE_LOCAL_STORAGE_KEY,
      getSuggestedCurrencyCodeForLocale(locale)
    );

  const { ipInfo, ipInfoLoaded, isInEurozone } = useLookupIP();
  const ipIsoCode: CountryCode =
    ipInfo?.country?.isoCode &&
    Object.values(CountryCode).includes(ipInfo.country.isoCode as CountryCode)
      ? CountryCode[ipInfo.country.isoCode as CountryCode]
      : CountryCode.US;

  const setCurrency = React.useCallback(
    async (newCurrencyCode: CurrencyCode) => {
      try {
        if (isLoggedIn) {
          await updateCurrentUser({
            variables: {
              default_currency: newCurrencyCode
            }
          });
        }
        setSelectedCurrencyLocalStorage(newCurrencyCode);
      } catch (err) {
        console.error(err);
      }
    },
    [isLoggedIn, updateCurrentUser, setSelectedCurrencyLocalStorage]
  );

  // Get user's country from the currency set in local storage,
  // default_currency, IP's country, or default to US.
  const countryCode: CountryCode =
    getCountryFromCurrency(currentUserDefaultCurrencyCode) ||
    getCountryFromCurrency(selectedCurrencyLocalStorage) ||
    CountryCode[ipIsoCode];
  const selectedCountryInfo = COUNTRIES_INFO[countryCode];
  const currencyCode = getCurrencyFromCountryIsoCode(countryCode, isInEurozone);

  const currentUserExists = !!currentUser;
  React.useEffect(() => {
    // Update user's default_currency in the event they don't have one, but only
    // if they're *fully* logged in.
    if (currentUserExists && !currentUserDefaultCurrencyCode && currencyCode) {
      updateCurrentUser({
        variables: {
          default_currency: currencyCode
        }
      });
    }
  }, [
    currentUserExists,
    currentUserDefaultCurrencyCode,
    currencyCode,
    updateCurrentUser
  ]);

  React.useEffect(() => {
    // Keep selectedCurrencyLocalStorage up to date.
    if (selectedCurrencyLocalStorage !== currencyCode) {
      setSelectedCurrencyLocalStorage(currencyCode);
    }
  }, [
    locale,
    selectedCurrencyLocalStorage,
    currencyCode,
    setSelectedCurrencyLocalStorage
  ]);

  return (
    <SetCurrencyLocalizationContext.Provider value={{ setCurrency }}>
      <CurrencyLocalizationContext.Provider
        value={{
          currencyCode,
          countryCode,
          selectedCurrencyCountry: selectedCountryInfo,
          isLoading: currentUserIsLoading && !ipInfoLoaded,
          hasLoaded: currentUserHasLoaded
        }}
      >
        {children}
      </CurrencyLocalizationContext.Provider>
    </SetCurrencyLocalizationContext.Provider>
  );
};

export const LocalizationProvider = React.memo(
  ({
    children,
    userSelectedLocale
  }: React.PropsWithChildren<{ userSelectedLocale?: I18nLocale }>) => (
    <LocaleProvider userSelectedLocale={userSelectedLocale}>
      <PseudoLocalizationProvider>
        <TimeZoneProvider>
          <CurrencyProvider>{children}</CurrencyProvider>
        </TimeZoneProvider>
      </PseudoLocalizationProvider>
    </LocaleProvider>
  )
);

interface WithLocaleProps {
  locale: I18nLocale;
}

export function withLocale<T>() {
  return (WrappedComponent: React.ComponentType<T & WithLocaleProps>) =>
    (props: T) => {
      const locale = useLocale();
      return <WrappedComponent {...props} locale={locale} />;
    };
}
