import { Label } from "@outschool/ui-legacy-component-library";
import { pxToRem } from "@outschool/ui-utils";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

function makeUnreadCountLabel(n: number, max: number = 99) {
  return n > max ? `${max}+` : `${n}`;
}

export default function UnreadMessagesCount({
  count,
  color = "#929eaa",
  sx = {}
}: {
  count?: number;
  color?: string;
  sx?: SystemStyleObject;
}) {
  if (typeof count !== "number" || count === 0) {
    return null;
  }

  return (
    <Label
      data-test-id="unread-message-count"
      color={color}
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "2em",
        height: "20px",
        minWidth: "20px",
        marginLeft: pxToRem(8),
        ...sx
      }}
    >
      {makeUnreadCountLabel(count)}
    </Label>
  );
}
