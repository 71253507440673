import {
  Alert,
  Box,
  Button,
  Icon,
  Image,
  MenuItem,
  Select,
  Skeleton,
  Typography,
  useTheme
} from "@outschool/backpack";
import {
  ActiveClassesQuery as ActiveClassesQueryType,
  ActiveClassesQueryVariables,
  ActiveEnrollmentsByClassQuery as ActiveEnrollmentsByClassQueryType,
  ActiveEnrollmentsByClassQueryVariables
} from "@outschool/gql-frontend-generated";
import {
  faCheck,
  faCheckCircle,
  faDiamondExclamation,
  faUpRightFromSquare
} from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { leadActivitiesPath } from "@outschool/routes";
import { plural } from "@outschool/text";
import {
  gql,
  useMutation,
  useQueryWithPreviousData
} from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import { useFeatureFlag } from "@outschool/ui-components-shared";
import { Checkbox, TextArea } from "@outschool/ui-legacy-component-library";
import { useLinkComponent, useNavigation } from "@outschool/ui-utils";
import * as _ from "lodash";
import React from "react";

import { uuidv4 } from "../../shared/helpers";
import useSectionString from "../../shared/hooks/useSectionString";
import HeaderTags from "../components/HeaderTags";
import SimpleLayout from "../components/layouts/SimpleLayout";
import { SellerOrgToolbar } from "../components/nav/SellerOrgToolbar";
import {
  ActiveClassesQuery,
  ActiveEnrollmentsByClassQuery
} from "../queries/LeaderActiveEnrollmentsQuery";

export const sendMessageMutation = gql`
  mutation BulkSendMessageToUser(
    $messageInput: MessageInput!
    $recipientUids: [ID!]!
  ) {
    sendMessage(messageInput: $messageInput, recipientUids: $recipientUids) {
      uid
    }
  }
`;

type EnrollmentsType = Array<EnrollmentType>;

type EnrollmentType = { uid: string; sectionUid: string };

const useSectionsData = ({ activityUid, canUseFeature }) => {
  const { loading: sectionsLoading, data: sectionsData } =
    useQueryWithPreviousData<
      ActiveEnrollmentsByClassQueryType,
      ActiveEnrollmentsByClassQueryVariables
    >(ActiveEnrollmentsByClassQuery, {
      variables: {
        activityUid: activityUid
      },
      skip: !canUseFeature || !activityUid
    });

  return { sectionsData, sectionsLoading };
};

export const useBroadcastMessageFlag = currentUser => {
  const isFeatureOn = useFeatureFlag("bulk-messaging");
  const isUserSellerOrgAdmin = !!currentUser?.sellerOrg?.currentUserIsOwner;
  const isUserIndependentTeacher =
    !currentUser?.sellerOrg?.currentUserIsTeacher &&
    !!currentUser?.teacher_approved_at;
  return isFeatureOn && (isUserSellerOrgAdmin || isUserIndependentTeacher);
};

export default function TeacherBulkMessagePage(props) {
  const { t } = useTranslation("client\\routes\\teach\\broadcast");
  const { currentUser, currentUserIsLoading } = useSession();
  const navigate = useNavigation();
  const Link = useLinkComponent();

  const [sendMessage] = useMutation(sendMessageMutation);

  const [percentComplete, setPercentComplete] = React.useState<number>(0);
  const [sending, setSending] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [activityUid, setActivityUid] = React.useState<string | undefined>(
    undefined
  );
  const [sectionUids, setSectionUids] = React.useState<Array<string>>([]);
  const [message, setMessage] = React.useState<string>("");

  const { briefDurationString } = useSectionString();
  const theme = useTheme();

  const canUseFeature = useBroadcastMessageFlag(currentUser);

  const { loading, data } = useQueryWithPreviousData<
    ActiveClassesQueryType,
    ActiveClassesQueryVariables
  >(ActiveClassesQuery, {
    skip: !canUseFeature
  });

  const { sectionsData, sectionsLoading } = useSectionsData({
    activityUid,
    canUseFeature
  });

  React.useEffect(() => {
    if (!!currentUser && !canUseFeature) {
      navigate(leadActivitiesPath());
    }
  }, [currentUser, canUseFeature, navigate]);

  React.useEffect(() => {
    if (!!sectionsData) {
      setSectionUids(
        sectionsData.activity.currentAndFutureSections.map(s => s.uid)
      );
    }
  }, [activityUid, sectionsData]);

  const isLoading = !currentUser || currentUserIsLoading || loading;
  const classes = (data?.currentUser || {}).futureEndingActivities;

  const enrollments: EnrollmentsType = React.useMemo(() => {
    const enrollmentsForSection: EnrollmentsType = [];
    const sections = sectionsData?.activity?.currentAndFutureSections;

    if (!sections) {
      return [];
    }

    for (const section of sections) {
      if (section.enrollments.length) {
        enrollmentsForSection.push(
          ...section.enrollments.map(c => ({
            uid: c.parent.uid,
            sectionUid: section.uid
          }))
        );
      }
    }

    return enrollmentsForSection;
  }, [sectionsData]);

  const recipients = enrollments.filter(
    ({ sectionUid }: EnrollmentType) =>
      !!sectionUids.length && sectionUids.includes(sectionUid)
  );

  return (
    <SimpleLayout
      {...props}
      contentSx={theme => ({
        maxWidth: 1300,
        mr: "auto",
        ml: "auto",
        [theme.breakpoints.up("lg")]: {
          maxWidth: 1300
        },
        [theme.breakpoints.down("lg")]: {
          maxWidth: "100%"
        }
      })}
    >
      <HeaderTags title={t("Broadcast Message")} />
      <SellerOrgToolbar />
      <Box
        flex
        sx={theme => ({
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
          mt: "2rem",
          mr: "auto",
          ml: "auto",
          padding: "1rem",
          [theme.breakpoints.up("lg")]: {
            maxWidth: 1300
          },
          [theme.breakpoints.down("lg")]: {
            maxWidth: "100%"
          }
        })}
      >
        <Typography>
          {t(
            "This message will send to all parents with an active enrolment in your selected class or section."
          )}
        </Typography>
        <DisclaimerBoxes t={t} />
        <Box
          flex
          sx={theme => ({
            gap: "2rem",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            [theme.breakpoints.down("lg")]: {
              flexDirection: "column",
              alignItems: "center"
            }
          })}
        >
          <Box
            flex
            sx={theme => ({
              flexDirection: "column",
              gap: "1rem",
              width: "50%",
              maxWidth: 500,
              [theme.breakpoints.down("lg")]: {
                width: "100%"
              }
            })}
          >
            <Box
              flex
              sx={theme => ({
                flexDirection: "column",
                gap: "1.5rem",
                backgroundColor: theme.palette.neutral[50],
                padding: 15,
                borderRadius: 10
              })}
            >
              <Typography variant="h6">{t("Select a class")}</Typography>
              {isLoading && (
                <Box
                  flex
                  sx={{
                    height: 80,
                    alignItems: "center",
                    width: "100%",
                    marginTop: "-0.7rem",
                    marginBottom: 5
                  }}
                >
                  <Skeleton
                    sx={theme => ({
                      height: 145,
                      width: "100%",
                      borderRadius: 10,
                      border: theme.palette.neutral[200]
                    })}
                  />
                </Box>
              )}

              {!isLoading && (
                <>
                  <Select
                    value={activityUid}
                    onChange={e => setActivityUid(e.target.value)}
                    sx={{ textWrap: "pretty" }}
                    selectSx={{ textWrap: "pretty" }}
                  >
                    {classes &&
                      classes.map(cls => {
                        return (
                          <MenuItem
                            key={cls.uid}
                            value={cls.uid}
                            sx={theme => ({
                              borderBottom: `1px solid ${theme.palette.neutral[100]}`,
                              textWrap: "pretty"
                            })}
                          >
                            <Box
                              flex
                              sx={{
                                flexDirection: "row",
                                width: "100%"
                              }}
                            >
                              <Image
                                src={cls.details.photo || ""}
                                sx={{
                                  width: 55,
                                  height: 45,
                                  marginRight: "1rem"
                                }}
                              />
                              <Box flex sx={{ flexDirection: "column" }}>
                                <Box>
                                  <Typography
                                    as="p"
                                    emphasized
                                    sx={{ textWrap: "pretty" }}
                                  >
                                    {cls.title}
                                  </Typography>
                                </Box>
                                <Box>
                                  {t("Ages")} {cls.age_min}-{cls.age_max}
                                </Box>
                              </Box>
                            </Box>
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <Typography variant="h6">
                    {t("Select the sections (optional)")}
                  </Typography>
                  <Box
                    flex
                    sx={{
                      flexDirection: "column",
                      gap: "0.5rem"
                      // ml: "1rem"
                    }}
                  >
                    {sectionsLoading && (
                      <Box
                        flex
                        sx={{
                          height: 80,
                          alignItems: "center",
                          width: "100%",
                          marginTop: "-0.7rem",
                          marginBottom: 5
                        }}
                      >
                        <Skeleton
                          sx={theme => ({
                            height: 145,
                            width: "100%",
                            borderRadius: 10,
                            border: theme.palette.neutral[200]
                          })}
                        />
                      </Box>
                    )}
                    {!sectionsLoading &&
                      activityUid &&
                      sectionsData?.activity?.currentAndFutureSections.map(
                        section => {
                          return (
                            <Box
                              key={section.uid}
                              flex
                              sx={{ justifyContent: "space-between" }}
                            >
                              <Checkbox
                                id={section.uid}
                                value={section.uid}
                                onChange={e => {
                                  if (
                                    !!sectionUids.find(s => s === section.uid)
                                  ) {
                                    setSectionUids(
                                      sectionUids.filter(s => s !== section.uid)
                                    );
                                  } else {
                                    setSectionUids([
                                      ...sectionUids,
                                      e.target.value
                                    ]);
                                  }
                                }}
                                checked={
                                  !!sectionUids.find(s => s === section.uid)
                                }
                                label={
                                  <Box
                                    flex
                                    sx={{
                                      flexDirection: "column",
                                      width: "100%"
                                    }}
                                  >
                                    <Typography>
                                      {classes &&
                                        briefDurationString(
                                          classes.find(
                                            c => c.uid === activityUid
                                          )!,
                                          section,
                                          currentUser.location!.timeZone!
                                        )}
                                    </Typography>
                                    <Typography variant="body2">
                                      {t("({{filledSpaceCount}} {{parents}})", {
                                        parents: plural(
                                          "parent",
                                          section.filledSpaceCount ?? 0
                                        ),
                                        filledSpaceCount:
                                          section.filledSpaceCount ?? 0
                                      })}
                                    </Typography>
                                  </Box>
                                }
                              />
                              <Link
                                target={"_blank"}
                                to={"/classroom/" + section.uid}
                                style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <Box
                                  flex
                                  sx={{
                                    justifyContent: "center",
                                    gap: "0.5rem",
                                    mt: "auto",
                                    mb: "auto",
                                    p: 5
                                  }}
                                >
                                  <Typography>{t("See Section")}</Typography>
                                  <Icon
                                    icon={faUpRightFromSquare}
                                    sx={{ ml: 5 }}
                                  />
                                </Box>
                              </Link>
                            </Box>
                          );
                        }
                      )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box
            flex
            sx={{
              flexDirection: "column",
              gap: "1rem",
              width: "100%",
              maxWidth: 500
            }}
          >
            <TextArea
              onChange={e => setMessage((e.target as HTMLInputElement).value)}
              placeholder={t(
                "There's a holiday next week so there is no class..."
              )}
              value={message}
              sx={{
                width: "100%",
                minHeight: 210,
                borderColor: theme.palette.neutral[300],
                borderRadius: 10
              }}
            />
            {success && (
              <Box sx={{ maxHeight: "4rem", mb: "1rem" }}>
                <Alert severity="success">
                  {t("Your message was sent successfully!")}
                </Alert>
              </Box>
            )}
            {sending && (
              <Box
                sx={{
                  width: "100%"
                }}
              >
                <Alert severity="warning">
                  <Box
                    flex
                    sx={theme => ({
                      width: "100%",
                      alignItems: "flex-start",
                      height: "2rem",
                      backgroundColor: theme.palette.neutral[200],
                      borderRadius: 10,
                      mb: 5
                    })}
                  >
                    <Box
                      sx={theme => ({
                        width: `${percentComplete}%`,
                        height: "2rem",
                        backgroundColor: theme.palette.success[400],
                        borderRadius: 10,
                        transition: "width 1s ease-in-out"
                      })}
                    />
                  </Box>
                  <Typography>
                    {t(
                      "Warning, don't leave or change this page before sending is completed; or your message will not be delivered to all recipients"
                    )}
                  </Typography>
                </Alert>
              </Box>
            )}
            <Button
              onClick={async () => {
                setSending(true);
                // generate a uuid for the bulk_group_uid
                const bulkGroupUid = uuidv4();
                const dedupedRecipients = _.uniqBy(recipients, "uid");

                let i = 0;
                while (i < dedupedRecipients.length) {
                  const BATCH_SIZE = 10;
                  const endIndex = i + BATCH_SIZE;

                  await Promise.all(
                    dedupedRecipients.slice(i, endIndex).map((_, index) => {
                      return new Promise(async (resolve, reject) => {
                        const recipient = dedupedRecipients[i + index];
                        try {
                          await sendMessage({
                            variables: {
                              recipientUids: [recipient.uid],
                              messageInput: {
                                content: message,
                                activityUid: activityUid,
                                sectionUid: recipient.sectionUid,
                                bulkGroupUid,
                                details: {
                                  action: "broadcast message",
                                  subject: t(
                                    "Broadcast message from {{teacher}}",
                                    { teacher: currentUser?.name }
                                  )
                                }
                              }
                            }
                          });
                        } catch (err) {
                          reject(recipient);
                        }
                        resolve(recipient);
                      });
                    })
                  );
                  i = endIndex;
                  setPercentComplete(
                    Math.min(~~((i / dedupedRecipients.length) * 100), 100)
                  );

                  await new Promise(resolve =>
                    setTimeout(() => resolve(true), 1000)
                  );
                }
                // if there isn't many recipients make the warning stick for longer
                // before showing success so users can read the message
                if (dedupedRecipients.length < 20) {
                  setTimeout(() => {
                    setSending(false);
                    setSuccess(true);
                    setPercentComplete(0);
                    setTimeout(() => setSuccess(false), 3000);
                  }, 2000);
                } else {
                  setSending(false);
                  setSuccess(true);
                  setPercentComplete(0);
                  setTimeout(() => setSuccess(false), 3000);
                }
              }}
              variant="contained"
              sx={{ marginLeft: "auto", width: 300 }}
              disabled={!recipients.length || sending || success || !message}
            >
              {sending
                ? t("Sending...")
                : success
                ? t("Sent")
                : t("Send Message to {{recipientsLength}} {{parents}}", {
                    parents: plural("parent", recipients.length),
                    recipientsLength: recipients.length
                  })}
            </Button>
          </Box>
        </Box>
      </Box>
    </SimpleLayout>
  );
}

function DisclaimerBoxes({ t }) {
  return (
    <Box
      flex
      sx={{
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <Box
        flex
        sx={theme => ({
          maxWidth: 1020,
          flexDirection: "row",
          gap: "2rem",
          [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
            maxWidth: 500,
            alignItems: "center"
          }
        })}
      >
        <Box
          flex
          sx={{
            border: "solid 1px #4CAF50",
            backgroundColor: "#EEF7EE",
            flexDirection: "row",
            borderRadius: "8px",
            marginBottom: "1rem",
            paddingBottom: "1rem",
            width: "100%"
          }}
        >
          <Icon
            icon={faCheckCircle}
            sx={{
              marginY: "1.2em",
              marginX: "0.8em",
              color: "#4CAF50"
            }}
          />
          <Box
            flex
            sx={{
              flexDirection: "column",
              margin: "0.3em"
            }}
          >
            <Typography
              variant="subtitle1"
              sx={theme => ({
                alignSelf: "start",
                marginY: "0.5em",
                marginRight: "0.8em",
                fontWeight: 700,
                color: theme.palette.success[700]
              })}
            >
              {t("Do")}
            </Typography>
            <Typography
              variant="body2"
              sx={theme => ({
                alignSelf: "start",
                marginRight: "0.7em",
                color: theme.palette.success[700]
              })}
            >
              {t(
                "use this interface to make parents aware of general announcements and time sensitive information, such as:"
              )}
            </Typography>
            <Box>
              <Icon
                icon={faCheck}
                sx={theme => ({ color: theme.palette.success[700] })}
              />
              <Typography
                variant="body2"
                sx={theme => ({
                  marginLeft: "1rem",
                  alignSelf: "start",
                  marginRight: "0.7em",
                  color: theme.palette.success[700]
                })}
              >
                {t("class information and reminders")}
              </Typography>
            </Box>
            <Box>
              <Icon
                icon={faCheck}
                sx={theme => ({ color: theme.palette.success[700] })}
              />
              <Typography
                variant="body2"
                sx={theme => ({
                  marginLeft: "1rem",
                  alignSelf: "start",
                  marginRight: "0.7em",
                  color: theme.palette.success[700]
                })}
              >
                {t("vacation notices")}
              </Typography>
            </Box>
            <Box>
              <Icon
                icon={faCheck}
                sx={theme => ({ color: theme.palette.success[700] })}
              />
              <Typography
                variant="body2"
                sx={theme => ({
                  marginLeft: "1rem",
                  alignSelf: "start",
                  marginRight: "0.7em",
                  color: theme.palette.success[700]
                })}
              >
                {t("emergency cancellations")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          flex
          sx={{
            border: "solid 1px #D32F2F",
            backgroundColor: "#FFF2EE",
            flexDirection: "row",
            borderRadius: "8px",
            marginBottom: "1rem",
            paddingBottom: "1rem",
            width: "100%"
          }}
        >
          <Icon
            icon={faDiamondExclamation}
            sx={{
              marginY: "1.2em",
              marginX: "0.8em",
              color: "#D32F2F"
            }}
          />
          <Box
            flex
            sx={{
              flexDirection: "column",
              margin: "0.3em"
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                alignSelf: "start",
                marginY: "0.5em",
                fontWeight: 700,
                marginRight: "0.8em",
                color: "#8F1104"
              }}
            >
              {t("Don't")}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                alignSelf: "start",
                marginRight: "0.7em",
                color: "#8F1104"
              }}
            >
              {t(
                "send messages to parents who have asked not to receive anymore messages. Abuse of this feature is considered against Outschool policies and could result in actions against your account."
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
